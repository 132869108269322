import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import axios from "axios";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  // console.log("toooooo ", to);

  if (to.query.share_token) {
    var decoded = await jwt_decode(to.query.share_token);

    console.log(decoded);

    try {
      const res = await axios.post(process.env.VUE_APP_BASE_URL_API + "auth/sharetoken", {
        shareToken: decoded.access_token
      });
      // console.log(response.data.data.access_token);
      if (res.data.result == "Success") {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("account_id", res.data.account_id);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("refresh_token", res.data.refresh_token);
      }

      next({ ...to, query: {} });
    } catch (error) {
      console.log(error);
    }
  } else {
    next();
  }
});

export default router;
