<template>
  <div>
    <i-text-field v-model="search" label="ค้นหารายชื่อโครงการ"></i-text-field>
    <div class="home-title">หน่วยงานและมูลนิธิ</div>
    <card-list :items="projects">
      <template v-slot:item="{ id, name, image, details, endTime, amount, total }">
        <i-card style="padding: 0">
          <div class="project-card-container">
            <div class="project-content">
              <div class="project-content-top">
                <div class="image">
                  <img :src="`${baseUrl}/media/image${image}`" alt="image" />
                </div>
                <div class="content" @click="$router.push(`/project/${id}`)">
                  <div>
                    <div class="card-title">
                      {{ name }}
                    </div>
                    <div class="card-desc">
                      {{ details }}
                    </div>
                  </div>
                  <div class="end-date">วันที่สิ้นสุด: {{ dateFormat(endTime) }}</div>
                </div>
              </div>
              <div class="project-content-bottom">
                <div class="left">
                  <div class="point-title">
                    ยอดแต้มสะสม
                  </div>
                  <div class="point-value">{{ total }} แต้ม</div>
                </div>
                <div class="right">
                  <div class="point-title">
                    ยอดแต้มคงเหลือ
                  </div>
                  <div class="point-value">{{ amount }} แต้ม</div>
                </div>
              </div>
            </div>
            <div class="project-action" @click="transferClick(id)">
              โอนแต้ม
            </div>
          </div>
        </i-card>
        <br />
      </template>
    </card-list>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import CardList from "components/card/card-list.vue";
const baseUrl = process.env.VUE_APP_BASE_URL_API;
export default {
  components: {
    CardList
  },
  data: () => ({
    search: null,
    image: require("assets/image/preview.png"),
    baseUrl
  }),
  computed: {
    ...mapState("project", ["projects"])
  },
  async mounted() {
    await this.getProjects();
  },
  methods: {
    ...mapActions("project", ["getProjects"]),
    dateFormat(val) {
      return val ? moment(val).format("DD/MM/YYYY") : "ไม่สิ้นสุด";
    },
    transferClick(id) {
      this.$router.push(`/project/${id}/transfer`);
    }
  }
};
</script>

<style scoped>
.project-card-container {
  display: flex;
}
.project-content {
  flex: 1;
  background: #fff;
}
.project-content-top {
  padding: 12px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
}
.project-content-top .image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 16px;
}
.project-content-top .image img {
  width: 100px;
  height: 100px;
}
.project-content-top .content {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.project-content-bottom {
  padding: 12px;
  display: flex;
  height: 90px;
}
.project-content-bottom .left {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.project-content-bottom .right {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.project-action {
  width: 70px;
  /* height: 217px; */
  left: 296px;
  top: 0px;
  background: #1e4d94;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  /* Color / Neutral / #333333 */

  color: #333333;
}
.card-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 157% */

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  color: #aeaeae;
}
.end-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: #333333;
}
.point-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333;
}
.point-value {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e4d94;
}
</style>

<style>
.point-container {
  display: flex;
  justify-content: space-between;
}
.point-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333;
}
.point-value {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e4d94;
}
</style>
