<template>
  <div>
    <v-dialog v-model="localvalue" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>รายละเอียด</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
          <v-btn icon dark @click="localvalue = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list three-line subheader class="pb-0">
          <!-- <v-subheader>User Controls</v-subheader> -->
          <v-list-item>
            <v-list-item-content>
              <!-- <v-list-item-title>รายละเอียด</v-list-item-title> -->
              <v-list-item-content
                >{{ data.title }} {{ data.firstName }} {{ data.lastName }}</v-list-item-content
              >
              <v-list-item-content>เบอร์โทรศัพท์ : {{ data.phone }} </v-list-item-content>
              <v-list-item-content>อีเมล : {{ data.email }}</v-list-item-content>
              <v-list-item-content v-show="data.orderId"
                >OderID : {{ data.orderId }}</v-list-item-content
              >
              <v-list-item-content
                >ชื่อผู้สั่งอาหาร : {{ data.orderer ? data.orderer : "" }}
              </v-list-item-content>
              <v-list-item-content v-if="data.useTime"
                >เวลาสั่งอาหาร : {{ timeCoupon }}
              </v-list-item-content>
              <v-list-item-content class="text-inline"
                >สถานะ :
                <div :style="{ color: statusColor(data.status) }" class="text-inline">
                  {{ statusTexS(data.status) }}
                </div></v-list-item-content
              >
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader><h3>คูปอง</h3> </v-subheader>
          <div v-for="(item, index) in coupon" :key="index">
            <v-list-item class="mb-2" style="background:#f5f5f5">
              <v-list-item-content>
                <v-btn
                  v-show="
                    item.couponStatus == 'not' && item.couponStatusReservation == 'not_reserved'
                  "
                  icon
                  dark
                  style="top: 0; right: 0; position: absolute;"
                >
                  <v-icon size="22" @click="deleteItem(item)" color="#FF717C">
                    mdi-delete
                  </v-icon>
                </v-btn>
                <v-list-item-title>{{ item.couponName }}</v-list-item-title>
                <v-list-item-title
                  >ระยะเวลาใช้งาน : {{ dataTimeFormat(item.useCouponStartTime) }} -
                  {{ dataTimeFormat(item.useCouponEndTime) }}
                  <v-btn
                    v-show="item.couponStatus != 'use'"
                    class="ml-1"
                    small
                    color="#fcaa42"
                    dark
                    @click="extendTime(item)"
                  >
                    ขยายเวลา
                  </v-btn>
                </v-list-item-title>
                <v-list-item-title v-show="item.couponStatus == 'use'"
                  >สถานะคูปอง : ใช้แล้ว</v-list-item-title
                >
                <v-list-item-title
                  v-show="item.couponStatus == 'not' && item.couponStatusReservation == 'use'"
                  >สถานะคูปอง : จองคูปอง</v-list-item-title
                >
                <v-list-item-title
                  v-show="
                    item.couponStatus == 'not' && item.couponStatusReservation == 'not_reserved'
                  "
                  >สถานะคูปอง : ยังไม่ได้ใช้คูปอง</v-list-item-title
                >
                <v-list-item-title
                  v-show="
                    item.couponStatus == 'expired' && item.couponStatusReservation == 'not_reserved'
                  "
                  >สถานะคูปอง : คูปองหมดอายุ</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-card>

      <v-dialog v-model="dialog" max-width="290" persistent>
        <v-card style="border-radius: 16px;">
          <v-card-actions style="display: flex; padding: 20px 30px">
            <v-row>
              <v-col cols="12" style="font-weight: bold;">ขยายเวลา</v-col>
              <v-col cols="3 pb-0"> </v-col>
              <v-col cols="6 pb-0">
                <v-text-field
                  v-model="time"
                  dense
                  outlined
                  placeholder="0"
                  suffix="นาที"
                  required
                  :maxlength="3"
                  @keypress="validate_numeric($event)"
                  :rules="timeRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3 pb-0"> </v-col>

              <v-col cols="6" class="text-center">
                <v-btn color="#FF8576" outlined @click="cancel()">
                  ยกเลิก
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-btn color="#1e4d94" :disabled="!time || time == 0" @click="confirm(time)">
                  <span class="white--text" style="font-weight: bold;"> ยืนยัน </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BASE_URL_API;
import axios from "clients/axios";
import moment from "moment";

export default {
  props: ["value", "data"],
  data: () => ({
    baseUrl,
    coupon: [],
    dialog: false,
    data_time: {},
    time: null,
    time_update: null
  }),
  computed: {
    timeRules() {
      return [v => !!v || "กรุณากรอกเวลา", v => v > 0 || "กรุณากรอกเวลามากกว่า 0 นาที"];
    },
    localvalue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    timeCoupon() {
      return this.data.useTime ? moment(this.data.useTime).format("HH:mm น.") : "";
    }
  },
  created() {
    // this.getCoupon();
  },
  watch: {
    localvalue() {
      this.getCoupon();
    }
  },
  methods: {
    dataTimeFormat(date) {
      return date ? moment(date).format("HH:mm") : "";
    },
    dataDateFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    extendTime(v) {
      this.data_time = v;
      this.dialog = true;
    },
    cancel() {
      this.time = "";
      this.dialog = false;
    },
    async confirm(v) {
      if (v) {
        // let times = this.dataTimeFormat(this.data_time.useCouponEndTime) + v;
        const modifiedDate1 = new Date(this.data_time.useCouponEndTime.valueOf());
        let times = new Date(modifiedDate1.getTime() + v * 60000);

        this.time_update = this.dataTimeFormat(times);
        await this.putExtendTime(this.data_time);
      } else {
        this.$dialog({
          showCancel: false,
          type: "warning",
          desc: "เกิดข้อผิดพลาด"
        }).then(async () => {});
      }
    },
    async putExtendTime(val) {
      try {
        // console.log(val);
        await axios
          .put(
            `coupon/updatetimecoupon`,
            // `https://ngs2staging.nexgenshop.co.th/api/web-admin/api/v1/track-coupon/update/time`,
            {
              couponId: val.couponId,
              date: this.dataDateFormat(this.data_time.useCouponEndTime),
              time: this.time_update,
              oneId: val.oneId,
              amountUpdate: 0,
              userAssetId: val.userAssetId
            }
          )
          .then(async () => {
            this.$dialog({
              showCancel: false,
              type: "success",
              desc: "คุณได้ขยายเวลาเรียบร้อยแล้ว"
            }).then(async () => {
              await this.getCoupon();
              this.dialog = false;
            });
          })
          .catch(error => {
            this.$dialog({
              showCancel: false,
              type: "warning",
              desc: error.message
            }).then(async () => {});
          });
      } catch (error) {
        console.log(error);
      }
    },
    validate_numeric(e) {
      if (e.charCode >= 48 && e.charCode <= 57) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    statusTexS(val) {
      switch (val) {
        case "0":
          return "รอส่งคูปอง";
        case "1":
          return "ยังไม่ได้สั่งอาหาร";
        case "2":
          return "สั่งอาหาร";
        case "3":
          return "รับอาหาร";
        default:
          return "";
      }
    },
    statusColor(val) {
      switch (val) {
        case "0":
          return "#E9A016";
        case "1":
          return "#8bc34a";
        case "2":
          return "#4caf50";
        case "3":
          return "#2196f3";
        default:
          return "";
      }
    },
    async getCoupon() {
      // console.log(`couponId=${this.data.refId}&oneId=${this.data.userId}`);
      if(this.localvalue == true){
          try {
            const res = await axios.get(
              `${baseUrl}coupon/gettrackcoupon?couponId=${this.data.refId}&oneId=${this.data.userId}`,
            {}
          );
            const { data } = res.data;
            this.coupon = data;
          } catch (error) {
            console.log(error);
          }
      }
    },
    async deleteCoupon(val) {
      // console.log("delC");
      // console.log("val", val);
      // console.log(val.userAssetId);
      try {
        const res = await axios.post(`${baseUrl}coupon/canceltrackcoupon`, {
          id: this.data.id,
          couponId: val.couponId,
          oneId: val.oneId,
          listUserAssetId: [val.userAssetId]
        });
        const { data } = res.data;
        this.coupon = data;
      } catch (error) {
        console.log(error);
      }
    },

    deleteItem(val) {
      this.$dialog({
        // title: "Error",
        type: "warning",
        desc: `ยืนยันการยกเลิกคูปอง ${val.couponName} ?`,
        okLabel: "ยืนยัน",
        cancelLabel: "ยกเลิก",
        showCancel: true,

        onOk: async () => {
          await this.deleteCoupon(val);
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "success",
            desc: "คุณได้ลบคูปองเรียบร้อยแล้ว"
          }).then(async () => {
            // await this.getCoupon();
            this.localvalue = false
            this.$emit("getItems");
          });
        }
      });
      // console.log(val);
    }
  }
};
</script>

<style scoped>
.text-inline {
  display: inline;
}
</style>
