<template>
  <div class="card-list-container">
    <template v-if="items.length > 0">
      <template v-for="(item, i) in items">
        <template v-if="hasItemSlot">
          <slot name="item" v-bind="item" />
        </template>
        <card-item
          v-else
          :key="i"
          :title="item.name"
          :desc="item.details"
          :image="item.image"
          style="margin-bottom: 10px;"
        >
          <template v-slot:action>
            <i-button style="height: 32px" @click="handleClick(item[itemKey])">ร่วมบริจาค</i-button>
          </template>
        </card-item>
      </template>
    </template>
    <i-card v-else style="display:flex; justify-content: center">ไม่พบข้อมูล</i-card>
  </div>
</template>

<script>
import CardItem from "./card-item";
export default {
  props: {
    items: {
      default: () => []
    },
    itemKey: {
      default: () => "id"
    },
    path: {
      default: () => ""
    }
  },
  components: {
    CardItem
  },
  computed: {
    hasItemSlot() {
      return !!this.$scopedSlots.item || this.$slots.item;
    }
  },
  methods: {
    handleClick(key) {
      this.$router.push(`/${this.path}/${key}`);
    }
  }
};
</script>

<style scoped>
.card-list-container {
  padding: 20px 0 0 0;
}
</style>
