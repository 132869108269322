import {
  GET_API,
  GET_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_AGENCY_POINT_SUCCESS,
  SET_PROJECT,
  GET_FAILURE
} from "./constant";

export default {
  [GET_API]: state => {
    state.isFetching = true;
    state.error = null;
  },
  [GET_PROJECTS_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.projects = payload;
  },
  [GET_PROJECT_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.project = payload;
  },
  [GET_AGENCY_POINT_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.agencyPoint = payload;
  },
  [SET_PROJECT]: (state, payload) => {
    state.project = payload;
  },
  [GET_FAILURE]: (state, payload) => {
    state.isFetching = false;
    state.error = payload;
  }
};
