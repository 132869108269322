<template>
  <div>
    <div class="agency-image-container">
      <div class="agency-image">
        <img :src="localImage" />
      </div>
    </div>
    <div class="agency-detail">
      <i-card outlined>
        <div>
          <div class="agency-group-content">
            <div class="agency-title">ชื่อโครงการ</div>
            <div class="agency-sub-title">
              {{ agency.name }}
            </div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">ชื่อมูลนิธิ</div>
            <div class="agency-sub-title">
              {{ agency.agencyName }}
            </div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">วันที่สิ้นสุดกิจกรรม</div>
            <div class="agency-sub-title">{{ datePreview }}</div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">รายละเอียดการร่วมบริจาค</div>
            <div class="agency-sub-title">
              {{ agency.details }}
            </div>
          </div>
          <!-- <div class="agency-group-content">
            <div class="agency-title">สถานที่ร่วมกิจกรรม</div>
            <div class="agency-sub-title">
              {{ agency.location }}
            </div>
          </div> -->
        </div>
      </i-card>
    </div>
    <!-- consent -->
    <div @click="handleClickDialog" v-if="data_approved != 'approved'">
      <!-- v-if="data_approved != 'approved'" -->
      <v-checkbox v-model="checkbox" disabled>
        <template v-slot:label>
          <div
            style="font-family: Prompt;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px; color: #333333;"
          >
            ยอมรับเงื่อนไขเข้าร่วมดูแลโครงการ
          </div>
        </template>
      </v-checkbox>
    </div>
    <div class="detail-action">
      <i-button
        style="height: 45px"
        @click="handleClick"
        :disabled="!checkbox"
        v-if="data_approved != 'approved'"
        >ยืนยันการดูแลโครงการ</i-button
      >
      <i-button style="height: 45px" @click="handleClick" v-else>ยืนยันการดูแลโครงการ</i-button>
    </div>

    <FoundationDialog
      v-model="localDialog"
      @handel-accept="accept"
      :data_pdpa="data_pdpa"
      @handel-reject="rejectConsent"
    />
  </div>
</template>

<script>
import moment from "moment";
import FoundationDialog from "./modules/foundation-dialog.vue";
import { mapState, mapActions } from "vuex";
import axios from "clients/axios";
const baseUrl = process.env.VUE_APP_BASE_URL_API;

export default {
  components: {
    FoundationDialog
  },
  data: () => ({
    image: require("assets/image/preview.png"),
    checkbox: null,
    localDialog: null,
    id: null,
    approvePdpa: null,
    data_pdpa: "",
    data_approved: ""
  }),
  async mounted() {
    await this.getAgency(this.$route.params.id);
    this.id = this.$route.params.id;
  },
  created() {
    this.checkUserPdpa();
  },
  computed: {
    ...mapState("agency", ["isFetching", "agency"]),
    ...mapState("user", ["userAccount", "userPoint"]),
    localImage() {
      const baseUrl = process.env.VUE_APP_BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.agency.image ? `${baseUrl}media/image${this.agency.image}` : image;
    },
    datePreview() {
      return this.localStartDate + `${this.localEndDate ? ` - ${this.localEndDate}` : ""}`;
    },
    localStartDate() {
      return this.agency.startTime ? moment(this.agency.startTime).format("DD/MM/YYYY") : "";
    },
    localEndDate() {
      return this.agency.endTime ? moment(this.agency.endTime).format("DD/MM/YYYY") : "ไม่สิ้นสุด";
    }
  },
  methods: {
    ...mapActions("agency", ["getAgency"]),
    ...mapActions("user", ["getAcount", "getPoint", "donate"]),
    async handleClick() {
      const { id } = this;
      await axios.post("agency/approve", { agencyId: id });
      this.$dialog({
        // title: "Error",
        showCancel: false,
        type: "success",
        desc: "เข้าร่วมการดูแลโครงการเรียบร้อยแล้ว"
      }).then(() => {
        this.$router.push("/project");
      });
    },
    handleClickDialog() {
      this.localDialog = true;
    },
    accept() {
      this.checkbox = true;
      this.approvePDPA();
    },
    async rejectConsent() {
      const userId = localStorage.getItem("account_id");
      console.log("reject", userId);
      try {
        const res = await axios.get(`${baseUrl}consent/reject?userId=${userId}&service=foundation`);
        const { data } = res;
        console.log(data.message);
      } catch (error) {
        console.log(error);
      }
    },
    async checkUserPdpa() {
      const userId = localStorage.getItem("account_id");
      // const userId = "2648284886485780";
      console.log(userId);
      try {
        const res = await axios.get(`${baseUrl}consent?userId=${userId}&service=foundation`);
        const { data } = res;
        this.data_pdpa = data.data;
        this.data_approved = data.message;
        if (this.data_approved != "approved") {
          console.log("ไม่ผ่าน");
        } else {
          console.log("ผ่าน");
          // this.checkPDPA();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async approvePDPA() {
      const userId = localStorage.getItem("account_id");
      console.log("approve", userId);
      try {
        const res = await axios.get(
          `${baseUrl}consent/approve?userId=${userId}&service=foundation`
        );
        const { data } = res;
        console.log(data.message);
        this.checkUserPdpa();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.agency-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.agency-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
}
.agency-detail {
  margin-top: 10px;
}
.agency-group-content {
  margin: 15px 0;
}
.agency-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  /* margin: 5px 0; */
  margin-bottom: 15px;
}
.agency-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #aeaeae;
  margin: 5px 0;
}
</style>
