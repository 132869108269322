import {
  GET_API,
  GET_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_AGENCY_POINT_SUCCESS,
  SET_PROJECT,
  GET_FAILURE
} from "./constant";

import {
  getProjectsService,
  getProjectService,
  getAgencyPointService
} from "services/project.service";

export default {
  getProjects: async ({ commit }, payload) => {
    try {
      commit(GET_API);
      const result = await getProjectsService(payload);
      commit(GET_PROJECTS_SUCCESS, result);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  getProject: async ({ commit, dispatch }, payload) => {
    try {
      commit(GET_API);
      const result = await getProjectService(payload);
      const project = result || {};
      commit(GET_PROJECT_SUCCESS, project);
      dispatch("getAgencyPoint", project.id);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  getAgencyPoint: async ({ commit }, payload) => {
    try {
      commit(GET_API);
      const result = await getAgencyPointService(payload);
      commit(GET_AGENCY_POINT_SUCCESS, result);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  setProject: async ({ commit }, payload) => {
    try {
      commit(SET_PROJECT, payload);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  }
};
