import axios from "clients/axios";

export const getCouponsService = async id => {
  try {
    const res = await axios.get(`/donate-coupon/foundation/listcoupondonate/${id}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const botChatListRoomByEmail = async payload => {
  try {
    const res = await axios.get(`onechat/getlistroom/${payload}`);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const sendCoupon = async payload => {
  try {
    const res = await axios.post(`/donate-coupon/sendcoupon`, payload);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const getQuotapoint = async payload => {
  try {
    const res = await axios.get(`/donate-coupon/quotapoint/${payload}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const exchangeCoupon = async payload => {
  try {
    const res = await axios.post(`/donate-coupon/exchangecoupon`, payload);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getQuotapointHistory = async payload => {
  try {
    const res = await axios.get(`/donate-coupon/history/${payload}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCouponAmount = async payload => {
  try {
    console.log(payload);
    const res = await axios.get(
      // #nookp.haroon
      `/donate-coupon/couponamount?couponId=${payload.couponId}&agencyProjectId=${payload.agencyProjectId}`
      // `/donate-coupon/couponamount?couponId=61b09212b95e1b0776b23859&agencyProjectId=61b08b8c9f839b7c003072ca`
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getDonateAmount = async payload => {
  try {
    console.log(payload);
    const res = await axios.get(
      // #nookp.haroon
      `/donate-coupon/donateamount?couponId=${payload.couponId}&agencyProjectId=${payload.agencyProjectId}`
      // `donate-coupon/donateamount?couponId=61a6f601ce77292c16d6e2ae&agencyProjectId=61a48cadd9fe9d50de51bad6`
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
