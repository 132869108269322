import vuetify from "./vuetify";
import DialogComponent from "components/dialog/index.vue";
let vm = {};

// This exports the plugin object.
export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue) {
    const DialogPlugin = Vue.extend(DialogComponent);
    vm = new DialogPlugin({
      data: {},
      vuetify
    }).$mount();
    document.body.appendChild(vm.$el);
    Vue.prototype.$dialog = ({
      title,
      dialog = true,
      desc,
      cancelLabel,
      okLabel,
      image,
      showCancel = true,
      onOk,
      type = "general",
      icon,
      width = 290
    }) => {
      return new Promise(resolve => {
        vm.title = title;
        vm.dialog = dialog;
        vm.desc = desc;
        vm.cancelLabel = cancelLabel;
        vm.okLabel = okLabel;
        vm.image = image;
        vm.showCancel = showCancel;
        vm.type = type;
        vm.icon = icon;
        vm.width = width;
        vm.handleOk = async () => {
          try {
            if (onOk && typeof onOk === "function") {
              vm.loading = true;
              await onOk();
              vm.loading = false;
            } else {
              vm.dialog = false;
            }
          } catch (error) {
            vm.dialog = false;
          }
        };
        vm.$watch(
          "dialog",
          val => {
            if (val === false) {
              resolve(true);
            }
          },
          { immediate: true }
        );
      });
    };
  }
};
