export default {
  ["GET_COUPON_SUCSESS"]: (state, payload) => {
    state.coupons = payload;
  },
  ["SELECT_COUPON"]: (state, payload) => {
    state.coupon = payload;
  },
  ["GET_QUOTAPOINT_SUCSESS"]: (state, payload) => {
    state.quataPoint = payload;
  },
  ["GET_QUOTAHISTORY_SUCSESS"]: (state, payload) => {
    state.quataPointHistory = payload;
  },
  ["GET_COUPONAMOUNT_SUCSESS"]: (state, payload) => {
    state.couponAmount = payload;
  },
  ["GET_DONATEAMOUNT_SUCSESS"]: (state, payload) => {
    state.donateAmount = payload;
  }
};
