<template>
  <div>
    <v-row class="mx-n6 mt-n3">
      <nav class="topbar-container">
        <div class="topbar-inner">อนุมัติคูปอง</div>
      </nav>
    </v-row>
    <v-row no-gutters class="mt-7 mx-n6">
      <v-col>
        <v-tabs fixed-tabs v-model="select_type" center-active centered>
          <v-tab v-for="(packages_tab, index) in list" :key="index">
            <span :style="select_type == packages_tab.value ? 'color:#1E4D94' : 'color:grey'">{{
              packages_tab.title
            }}</span>
          </v-tab>
        </v-tabs>
        <v-row align="center" justify="center" no-gutters class="mt-3">
          <v-card class="py-2" width="100%" outlined style="border: none">
            <v-row justify="center">
              <!-- <v-col cols="1"></v-col> -->
              <v-col cols="5" sm="3" md="5" class="pa-4">
                <i-text-field
                  v-model="search"
                  label="ค้นหารายชื่อ"
                  hide-details
                  style="width: 100%"
                ></i-text-field>
              </v-col>

              <v-col cols="5" sm="3" md="5" class="pa-4">
                <i-select-ss
                  v-model="projectSelected"
                  label="เลือกโครงการ"
                  url="/agency/isolate"
                  item-text="name"
                  item-value="name"
                  hide-details
                ></i-select-ss>
              </v-col>

              <!-- <pre>{{ projectSelected }}</pre> -->
              <!-- <v-col cols="10" sm="3" md="5" class="pa-4">
                <i-text-field label="ค้นหาวันที่" hide-details style="width: 100%"></i-text-field>
              </v-col> -->
              <!-- <v-col cols="1"></v-col> -->
            </v-row>
          </v-card>
        </v-row>
        <v-tabs-items v-model="select_type" style="background-color: #f5f5f5">
          <v-tab-item class="bg-type mt-0 pt-7 px-7">
            <v-row align="center" justify="center">
              <v-col cols="12" v-for="(item, index) in couponWaiting" :key="index">
                <v-card class="card_coupon">
                  <v-row justify="center" align="center" no-gutters class="ma-0 pa-5">
                    <v-col cols="8">
                      <label class="lable_font_size"
                        >ชื่อ-สกุล :
                        <span class="lable_font_size"
                          >{{ item.firstNameTh }} {{ item.lastNameTh }}</span
                        ></label
                      >
                      <!-- <span>{{ item.name }}</span> -->
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <label
                        v-if="item.substitute == true"
                        class="lable_font_size"
                        style="color: red;"
                        >*หมายเหตุ สั่งแทน</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size">
                        ชื่อรพ. หน่วยงาน :
                        <span class="lable_font_size">{{ item.agencyName }}</span>
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >วันที่เริ่มต้น - สิ้นสุด :
                        <span class="lable_font_size"
                          >{{ dateFormat(item.start) }} - {{ dateFormat(item.end) }}</span
                        >
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >มื้อแรก-สิ้นสุด :
                        <span s class="lable_font_size"
                          >{{ item.firstMeal }} - {{ item.lastMeal }}</span
                        >
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >email :
                        <span class="lable_font_size">{{ item.email }}</span>
                        <v-btn
                          x-small
                          color="error"
                          v-if="item.countOneMail > 1"
                          class="lable_font_size ml-2 --text"
                          @click="email_over(item.email)"
                          >มีผู้ใช้ email นี้จำนวน {{ item.countOneMail }} คน
                        </v-btn>
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >ผู้รับผิดชอบ :
                        <span class="lable_font_size">{{ item.owner }}</span>
                      </label>
                    </v-col>

                    <v-row class="mt-1">
                      <v-col cols="6">
                        <v-btn
                          :disabled="disabledTime"
                          color="primary"
                          outlined
                          width="100%"
                          @click="ApporveCoupon(item.id)"
                        >
                          <span :class="disabledTime ? '' : 'btn_font_redeem'">อนุมัติ</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          color="#FF8576"
                          outlined
                          width="100%"
                          @click="RejectCouponWaiting(item)"
                        >
                          <span class="btn_font_redeem" style="color:#FF8576">ยกเลิก</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card>
              </v-col>
              <!-- <v-col class="mt-6" v-if="couponWaitingDetail.length > 0">
                <v-row class="save-btn-div" no-gutters>
                  <v-col>
                    <v-row>
                      <v-col cols="12" align="center">
                        <v-btn
                          :disabled="disabledTime"
                          style="background: #1e4d94"
                          width="90%"
                          height="40px"
                          @click="ApporveCouponAll(couponWaitingDetail)"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" style="color:white">
                              อนุมัติทั้งหมด
                            </v-col>
                          </v-row>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-tab-item>
          <v-tab-item class="bg-type mt-0 pt-7 px-7">
            <v-row align="center" justify="center">
              <v-col cols="12" v-for="(item, index) in couponApprove" :key="index">
                <v-card class="card_coupon">
                  <v-row justify="center" align="center" no-gutters class="ma-0 pa-5">
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >ชื่อ-สกุล :
                        <span class="lable_font_size"
                          >{{ item.firstNameTh }} {{ item.lastNameTh }}</span
                        ></label
                      >
                      <!-- <span>{{ item.name }}</span> -->
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size">
                        ชื่อรพ. หน่วยงาน :
                        <span class="lable_font_size">{{ item.agencyName }}</span>
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >วันที่เริ่มต้น - สิ้นสุด :
                        <span class="lable_font_size"
                          >{{ dateFormat(item.start) }} - {{ dateFormat(item.end) }}</span
                        >
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >มื้อแรก-สิ้นสุด :
                        <span s class="lable_font_size"
                          >{{ item.firstMeal }} - {{ item.lastMeal }}</span
                        >
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >email :
                        <span class="lable_font_size">{{ item.email }}</span>
                      </label>
                    </v-col>
                    <v-col cols="12">
                      <label class="lable_font_size"
                        >ผู้รับผิดชอบ :
                        <span class="lable_font_size">{{ item.owner }}</span>
                      </label>
                    </v-col>

                    <v-row>
                      <v-col cols="6">
                        <!-- <v-btn color="primary" outlined width="100%" @click="goTo()">
                          <span class="btn_font_redeem">อนุมัติ</span>
                        </v-btn> -->
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          color="#FF8576"
                          outlined
                          width="100%"
                          @click="RejectCouponApprove(item)"
                        >
                          <span class="btn_font_redeem" style="color:#FF8576">ยกเลิก</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card>
              </v-col>
              <!-- <v-col class="mt-6">
                <v-row class="save-btn-div" no-gutters>
                  <v-col>
                    <v-row>
                      <v-col cols="12" align="center">
                        <v-btn style="background: #1e4d94" width="90%" height="40px">
                          <v-row no-gutters>
                            <v-col cols="12" style="color:white">
                              ยกเลิกทั้งหมด
                            </v-col>
                          </v-row>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- #Email -->
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          รายชื่อผู้ใช้ email
        </v-card-title>
        <v-card-text class="black--text py-2" v-for="(item, index) in emailOver" :key="index">
          <span>{{ item.accountTitleTh }} </span>
          <span>{{ item.firstNameTh }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" outlined @click="dialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- #ลบคูปอง -->
    <v-dialog persistent v-model="dialog_del" max-width="auto">
      <v-card style="border-radius: 6px;" max-width="auto">
        <div class="image-container">
          <sweetalert-icon icon="warning" />
        </div>
        <div class="card-text-desc">
          ยืนยันการยกเลิก
          <div>คุณ {{ this.dataDelete.firstNameTh }} {{ this.dataDelete.lastNameTh }}</div>
        </div>
        <v-col class="px-12">
          <v-text-field
            v-model="causeDelete"
            label="สาเหตุการยกเลิก"
            :rules="delRules"
          ></v-text-field>
        </v-col>
        <v-card-actions style="display: flex; padding: 20px 30px">
          <div style="width: 100%;display: flex;justify-content: space-around;">
            <v-btn color="#FF8576" outlined @click="cancelDelete()">
              ยกเลิก
            </v-btn>
            <div>
              <i-button :disabled="!causeDelete" @click="confirmDelete()">
                ยืนยัน
              </i-button>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
// const baseUrl = process.env.VUE_APP_BASE_URL_API;
// import axios from "clients/axios";
export default {
  //   components: { CardRedeem },
  data() {
    return {
      image: require("assets/image/coupon.png"),
      select_type: 0,
      list: [
        {
          title: "รอยืนยัน",
          counter_visible: true,
          value: 0
        },
        {
          title: "ยืนยันแล้ว",
          counter_visible: true,
          value: 1
        }
      ],
      coupon_approve: [
        {
          name: "นาย ธีรชัย พันธ์ทิพย์",
          hospital: "โรงพยาบาล มอ.",
          period: "01-01-2022 ถึง 10-01-2022 ",
          meal: "lunch",
          email: "teerachai@thai.com",
          responsible: "ทดสอบ"
        },
        {
          name: "นาย เทส นะจ๊ะ",
          hospital: "โรงพยาบาล มอ.",
          period: "01-01-2022 ถึง 10-01-2022 ",
          meal: "breakfast",
          email: "teerachai@thai.com",
          responsible: "ทดสอบ"
        }
      ],
      value: 1,
      detail_approve: [],
      detail_waiting: [],
      search: "",
      projectSelected: null,
      dialog: false,
      dialog_del: false,
      causeDelete: "",
      dataDelete: {},
      interval: null,
      time: null
    };
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }).format();
    }, 1000);
  },
  methods: {
    ...mapActions("home_isolation", [
      "approveCoupon",
      "rejectCoupon",
      "getCoupons",
      "getCouponWaiting",
      "approveCouponAll",
      "getEmailOver"
    ]),

    goTo() {
      //   this.selectCoupon(val);
      //   this.$router.push("/redeem_detail");
      console.log("เลือก");
    },
    // statusColor(val) {
    //   if (val.pointAfter - val.pointBefore > 0) {
    //     return "#70BE45";
    //   } else {
    //     return "#D40000";
    //   }
    // },
    dateFormat(val) {
      return val ? moment(val).format("DD/MM/YY") : "ไม่ระบุ";
    },
    // async getItemsApprove() {
    //   try {
    //     const res = await axios.get(`${baseUrl}/syn/homeisolation/getpatientadmin/approve`);
    //     const { data } = res.data;
    //     this.detail_approve = data;
    //     console.log(this.detail_approve);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async getItemsWaiting() {
    //   try {
    //     const res = await axios.get(`${baseUrl}/syn/homeisolation/getpatientadmin/waiting`);
    //     const { data } = res.data;
    //     this.detail_waiting = data;
    //     console.log(this.detail_waiting);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async ApporveCoupon(item) {
      let body = item;

      this.$dialog({
        type: "warning",
        desc: `ยืนยันการอนุมัติคูปอง ?`,
        okLabel: "ยืนยัน",
        cancelLabel: "ยกเลิก",
        showCancel: true,

        onOk: async () => {
          await this.approveCoupon(body).then(() => {
            this.$dialog({
              showCancel: false,
              type: "success",
              desc: "อนุมัติคูปองสำเร็จ"
            }).then(async () => {
              await this.getCouponWaiting();
            });
          });
        }
      });
      console.log(body);
    },
    async ApporveCouponAll(item) {
      let body = item;

      this.$dialog({
        type: "warning",
        desc: `ยืนยันการอนุมัติคูปองทั้งหมด ?`,
        okLabel: "ยืนยัน",
        cancelLabel: "ยกเลิก",
        showCancel: true,

        onOk: async () => {
          await this.approveCouponAll(body).then(() => {
            this.$dialog({
              showCancel: false,
              type: "success",
              desc: "อนุมัติคูปองทั้งหมดสำเร็จ"
            }).then(async () => {
              await this.getCouponWaiting();
            });
          });
        }
      });
      console.log(body);
    },
    async RejectCouponWaiting(item) {
      console.log(item);
      this.dataDelete = item;
      this.dialog_del = true;
    },
    async RejectCouponApprove(item) {
      console.log(item);
      this.dataDelete = item;
      this.dialog_del = true;
    },
    email_over(v) {
      console.log(v);
      this.getEmailOver(v);
      console.log(this.emailOver);
      this.dialog = true;
    },
    confirmDelete() {
      console.log(this.causeDelete);
      console.log(this.select_type);

      let body = {
        id: this.dataDelete.id,
        cause: this.causeDelete
      };

      this.rejectCoupon(body).then(() => {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "success",
          desc: "ยกเลิกคูปองสำเร็จ"
        }).then(async () => {
          if (this.select_type == 0) {
            await this.getCouponWaiting();
          } else {
            await this.getCoupons();
          }
        });
      });

      this.dialog_del = false;
      this.causeDelete = "";
    },
    cancelDelete() {
      this.causeDelete = "";
      this.dialog_del = false;
    }
  },
  watch: {
    select_type(val) {
      console.log(val);
      if (val == 1) {
        console.log("ยืนยันแล้ว");
        this.getCoupons();
      } else {
        console.log("รอยืนยัน");
        this.getCouponWaiting();
      }
    }
  },
  computed: {
    // ...mapState("project", ["projects"]),
    ...mapState("home_isolation", ["couponApproveDetail", "couponWaitingDetail", "emailOver"]),
    delRules() {
      return [v => !!v || "กรุณากรอก สาเหตุการยกเลิก"];
    },
    couponApprove() {
      return this.couponApproveDetail.filter(item => {
        return (
          (this.projectSelected == null || item.agencyName == this.projectSelected) &&
          (!this.search ||
            item.firstNameTh.includes(this.search) ||
            item.lastNameTh.includes(this.search))
        );
      });
    },
    couponWaiting() {
      return this.couponWaitingDetail.filter(item => {
        return (
          (this.projectSelected == null || item.agencyName == this.projectSelected) &&
          (!this.search ||
            item.firstNameTh.includes(this.search) ||
            item.lastNameTh.includes(this.search))
        );
      });
    },
    disabledTime() {
      if (this.time >= "09:00" && this.time <= "09:10") {
        return true;
      } else if (this.time >= "13:00" && this.time <= "13:10") {
        return true;
      } else if (this.time >= "17:00" && this.time <= "17:10") {
        return true;
      } else {
        return false;
      }
    }
  },
  async mounted() {
    // await this.getItemsWaiting();
    await this.getCouponWaiting();
  }
};
</script>

<style scoped>
.topbar-container {
  width: 100%;
  display: flex;
  height: 70px;
  padding: 0 24px;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.25);
}
.topbar-inner {
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #333333;
}
.point_redeem {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #1e4d94;
}
.btn_font_redeem {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: #1e4d94;
}
.font_point {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 27px;
  color: #aeaeae;
}
.card_coupon {
  border-radius: 4px !important;
  background-color: #fff;
}
.tag {
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 80px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 80px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.lable_font_size {
  font-size: 10px;
}
.save-btn-div {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 50px;
  align-items: center;
  box-shadow: 2px 0px 4px rgba(199, 199, 199, 0.4);
  z-index: 1;
  background-color: #ffffff;
}
.card-text-desc {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #757575;
}
</style>
