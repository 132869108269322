<template>
  <div id="app">
    <v-app style="font-family: 'Prompt', sans-serif !important">
      <component :is="layout">
        <router-view />
      </component>
    </v-app>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BASE_URL_API;
import axios from "clients/axios";
export default {
  data() {
    return {
      data_pdpa: null
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "main-layout";
    }
  },
  created() {
    //check PDPA
    this.checkUserPdpa();
  },
  methods: {
    async checkUserPdpa() {
      const userId = localStorage.getItem("account_id");
      try {
        const res = await axios.get(`${baseUrl}consent?userId=${userId}&service=foundation`);
        const { data } = res;
        this.data_pdpa = data;
        console.log(this.data_pdpa);
        if (this.data_pdpa.message != "approved") {
          console.log("ไม่ผ่าน");
        } else {
          console.log("ผ่าน");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
html {
  background: #c7c7c7;
}
body {
  padding: 0;
  margin: 0;
  max-width: 768px;
  margin: auto;
}
.detail-action {
  margin-top: 20px;
  padding: 0 50px;
}
</style>
