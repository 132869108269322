<template>
  <div>
    <CardRedeem value="128" button="" />
    <!-- ****************************************************************************************************** -->
    <v-row style="background-color: #fff" class="mx-n6">
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <!--
              <div class="tag_detail">
                <v-row no-gutters>
                  <v-col cols="12" class="ml-1">
                    <span class="font_type">coupon</span>
                  </v-col>
                </v-row>
              </div>
              !-->
              <v-img
                :src="
                  coupon.couponDetail.couponImagePath ? coupon.couponDetail.couponImagePath : image
                "
              ></v-img>
            </v-col>
          </v-row>
          <!--
          <v-row no-gutters class="mb-2">
            <v-col cols="12" align="center">
              <span class="font_title">คูปองร่วมบริจาคอาหาร</span>
            </v-col>
          </v-row>
          !-->
        </v-card>
        <v-row class="mt-5">
          <v-col cols="12">
            <span class="font_point">{{ coupon.price }} </span>
            <span class="font_detail_2">แต้ม </span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ชื่อคูปองที่เปิดรับบริจาค</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">{{ coupon.couponName }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ราคา</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2"
              >{{ coupon.couponDetail.couponRule.discountAmount }} บาท</span
            >
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>รายละเอียดคูปอง</span>
          </v-col>
        </v-row>

        <v-row no-gutters class="mb-12">
          <v-col class="font_detail_2" cols="12">
            <span>{{ coupon.couponDetail.couponDetail.couponDescription }}</span>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 11rem"></v-row>

        <v-row class="save-btn-div" no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <span class="font_detail_2" style="margin-top: -5px">ระบุจำนวนคูปอง</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col align="center">
                <v-icon color="primary" :disabled="num == 1" size="37" @click="num_Minus(num)">
                  mdi-minus-box-outline</v-icon
                >
                <span class="ml-8 mr-8">{{ num }}</span>
                <v-icon color="primary" size="37" @click="num_Plus(num)"
                  >mdi-plus-box-outline</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center">
                <v-btn
                  style="background: #1e4d94"
                  width="90%"
                  height="40px"
                  @click="submitExchange"
                >
                  <v-row no-gutters>
                    <v-col align="left">
                      <span class="font_money">แลกคูปอง</span>
                    </v-col>
                    <v-col align="right">
                      <span class="font_money">{{ num * coupon.price }}</span>
                      <span class="font_money">แต้ม</span>
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardRedeem from "../../../components/card/card-redeem.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { CardRedeem },
  data() {
    return {
      image: require("assets/image/coupon.png"),
      detail_2:
        "สมาคมอาสาสร้างสุข ชวนท่านร่วมปันอีก 1 มื้อ เพื่อช่วยเหลือผู้ยากไร้ให้รอด <br><br> ยุคโควิด ยังมีคนลำบากอีกหลายชีวิตในหาดใหญ่ ที่เป็น" +
        "กลุ่มเสี่ยงติดเชื้อโควิด 19 และต้องกักตัวอยู่บ้านพร้อมกับความหิวโหยและความขาดแคลน มาร่วมส่งแรงใจให้ 'คนเมืองเดียวกัน' <br><br>" +
        "ได้มีกำลังพอที่จะกักตัวเพื่อลดความเสี่ยงต่อการแพร่เชื้อได้จนครบกำหนด ด้วยการร่วมส่งมอบอาหาร 1 มื้อ หรือมากกว่า ผ่านทางแอปพลิเคชั่น One Chat",
      search: "",
      count: 0,
      checkbox: false,
      num: 1,
      money_set: 50,
      money: 50,
      sheet: false
    };
  },
  watch: {},
  computed: {
    ...mapState("coupon", ["coupon", "quataPoint"]),
    ...mapState("project", ["projects"])
  },
  created() {},
  methods: {
    ...mapActions("coupon", ["exchangeCoupon"]),
    num_Minus(value) {
      if (value > 1) {
        this.num = value - 1;
      }
    },
    num_Plus(value) {
      if (
        (parseInt(this.num) + 1) * parseInt(this.coupon.price) <=
        parseInt(this.quataPoint.quotaPoint)
      ) {
        this.num = value + 1;
      } else {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "error",
          desc: `แต้มไม่เพียงพอ`
        });
      }
    },
    submitExchange() {
      let body = {
        agencyProjectId: this.projects[0].id,
        couponId: this.coupon.couponId,
        couponName: this.coupon.couponName,
        price: this.num * this.coupon.price,
        amount: this.num
      };
      this.exchangeCoupon(body).then(() => {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "success",
          desc: `แลกคูปองสำเร็จ`
        }).then(() => {
          this.member = [];
          this.$router.replace("/redeem_select");
        });
      });
    }
  }
};
</script>

<style scoped>
.tag_detail {
  /* width: 154px;
  height: 48px;
  
  background-color: #D32F2F;
  border-radius: 12px 6px 6px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; */
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 100px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 100px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_detail_2 {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.save-btn-div {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 180px;
  align-items: center;
  box-shadow: 2px 0px 4px rgba(199, 199, 199, 0.4);
  z-index: 1;
  background-color: #ffffff;
}
.font_money {
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.font_quota {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #1e4d94;
}
.font_btn_change {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #1e4d94;
}
.font_point {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  line-height: 18px;
  color: #1e4d94;
}
.line_hr {
  margin-left: 3%;
  margin-right: 3%;
  border-bottom: 2px solid #e0e0e0;
}
.font_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
</style>
