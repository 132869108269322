import actions from "./actions";
import mutations from "./mutations";

const state = {
  userAccount: {},
  userPoint: {},
  error: null,
  isFetching: false
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
