<template>
  <div>
    <br />
    โอนแต้ม
    <div class="project-detail">
      <i-card outlined>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="donation-title">ชื่อโครงการ</div>
          <i-select-ss
            v-model="projectSelected"
            label="เลือกโครงการ"
            url="/agency/getProjectApprove"
            item-text="name"
            item-value="id"
            required
            return-object
          ></i-select-ss>
          <div class="donation-title">
            จำนวนที่ต้องการบริจาค
          </div>
          <i-text-field
            v-model="amount"
            label="ระบุจำนวนที่ต้องการบริจาค"
            type="number"
            required
            :rules="amountRules"
          ></i-text-field>
          <div class="donation-title">
            ชื่อผู้รับ (เจ้าหน้าที่่)
          </div>
          <v-autocomplete
            v-model="value"
            :items="member"
            item-text="email"
            dense
            chips
            small-chips
            label="เพิ่มอีเมลผู้ดูแลโครงการ"
            outlined
            return-object
            class="i-text-field"
          ></v-autocomplete>
        </v-form>
      </i-card>
    </div>
    <div class="detail-action">
      <i-button style="height: 45px" @click="handleClick" :disabled="!value">โอนแต้ม</i-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "clients/axios";
export default {
  data: () => ({
    valid: true,
    id: null,
    amount: null,
    recieverSelected: null,
    member: [],
    value: null
  }),

  computed: {
    ...mapState("project", ["isFetching", "project", "agencyPoint"]),
    projectSelected: {
      get() {
        return this.project.id;
      },
      async set(val) {
        window.history.pushState({}, "", `/project/${val.id}/transfer`);
        this.setProject(val);
        await this.getAgencyPoint(val.id);
      }
    },
    amountRules() {
      return [
        v => !!v || "กรุณากรอกจำนวน",
        v => v > 0 || "กรุณากรอกจำนวนมากกว่า 0 แต้ม",
        v => {
          if (Number(v) > Number(this.agencyPoint.amount)) {
            return "แต้มไม่เพียงพอ";
          }
          return true;
        }
        //   v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ];
    }
  },
  methods: {
    ...mapActions("project", ["getAgencyPoint", "setProject"]),
    async handleClick() {
      if (this.$refs.form.validate()) {
        let body = await {
          agencyId: this.id,
          donorId: this.value.accountId,
          point: this.amount
        };
        console.log(this.value);
        await axios.post("agency/donateout", body);
        this.$dialog({
          showCancel: false,
          okLabel: "กลับหน้าหลัก",
          image: require("assets/thank-icon.svg"),
          desc: "คุณได้โอนแต้มเรียบร้อยแล้ว"
        }).then(() => {
          this.$router.push("/project");
        });
      }
      //   this.$router.push(`/project/${this.id}/transfer`);
    }
  },
  watch: {
    projectSelected: {
      handler(val) {
        console.log("DDD", val);
        this.id = val;
        axios
          .get(`agency/listagencymember/${this.id}`)
          .then(res => {
            const { data } = res.data;
            this.member = data.filter(val => {
              return val.status == "approve";
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      immediate: true
    }
  }
};
</script>

<style></style>
