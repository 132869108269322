import actions from "./actions";
import mutations from "./mutations";

const state = {
  isFetching: false,
  error: null,
  approve:null,
  reject:null,
  couponApproveDetail:[],
  couponWaitingDetail:[],
  emailOver:[] 
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
