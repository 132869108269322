<template>
  <div>
    <div class="project-image-container">
      <div class="project-image">
        <img :src="localImage" />
      </div>
    </div>
    <div class="project-detail">
      <i-card outlined>
        <div class="point-detail">
          <div class="point-container">
            <div class="point-title">
              ยอดแต้มคงเหลือ
            </div>
            <div class="point-value">{{ agencyPoint.amount }} แต้ม</div>
          </div>
          <div class="point-container">
            <div class="point-title">
              ยอดแต้มสะสม
            </div>
            <div class="point-value">{{ agencyPoint.total }} แต้ม</div>
          </div>
          <div class="point-container">
            <div class="point-title">
              หน่วยแต้มการโอน
            </div>
            <div>1 แต้ม = 1 บาท</div>
          </div>
        </div>
      </i-card>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    image: require("assets/image/preview.png"),
    checkbox: null,
    id: null
  }),
  async mounted() {
    await this.getProject(this.$route.params.id);
    this.$eventBus.$emit("set-title-topbar", this.project.name);
    this.id = this.$route.params.id;
  },
  beforeDestroy() {
    this.$eventBus.$emit("set-title-topbar", "");
  },
  computed: {
    ...mapState("project", ["isFetching", "project", "agencyPoint"]),
    localImage() {
      const baseUrl = process.env.VUE_APP_BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.project && this.project.image
        ? `${baseUrl}media/image${this.project.image}`
        : image;
    }
  },
  methods: {
    ...mapActions("project", ["getProject"])
  }
};
</script>

<style>
.project-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.project-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  height: 100px;
  flex: 1;
  justify-content: center;
}
.project-detail {
  margin-top: 10px;
}
.project-group-content {
  margin: 15px 0;
}
.project-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  /* margin: 5px 0; */
  margin-bottom: 15px;
}
.project-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #aeaeae;
  margin: 5px 0;
}
.point-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 110px;
}
</style>
