<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      left
      max-width="290px"
      min-width="auto"
      :return-value.sync="dates"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          :label="label"
          append-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          outlined
          dense
          class="i-text-field"
          :hide-details="hideDetails"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dates" no-title range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(dates)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
// import moment from "moment";

export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    }
  },
  watch: {
    dates(val) {
      this.dateRangeText = val;
    }
  },
  data: () => ({
    dates: [],
    menu: false
  }),
  computed: {
    dateRangeText: {
      get() {
        return typeof this.value === "object" ? this.value.join(" - ") : this.dates.join(" - ");
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
