import {
  GET_USER_POINT,
  GET_USER_POINT_SUCCESS,
  GET_USER_POINT_FAILURE,
  GET_USER_ACCOUNT,
  GET_USER_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_FAILURE
} from "./constant";

export default {
  [GET_USER_POINT]: state => {
    state.isFetching = true;
    state.error = null;
  },
  [GET_USER_POINT_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.userPoint = payload;
  },
  [GET_USER_POINT_FAILURE]: (state, payload) => {
    state.isFetching = false;
    state.error = payload;
  },
  [GET_USER_ACCOUNT]: state => {
    state.isFetching = true;
    state.error = null;
  },
  [GET_USER_ACCOUNT_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.userAccount = payload;
  },
  [GET_USER_ACCOUNT_FAILURE]: (state, payload) => {
    state.isFetching = false;
    state.error = payload;
  }
};
