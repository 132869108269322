import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import agency from "./modules/agency";
import project from "./modules/project";
import history from "./modules/history";
import user from "./modules/user";
import coupon from "./modules/coupon";
import home_isolation from "./modules/home_isolation";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    agency,
    project,
    history,
    user,
    home_isolation,
    coupon
  }
});
