import { GET_API, GET_HISTORY_SUCCESS, GET_FAILURE } from "./constant";

import { getHistoryService } from "services/history.service";

export default {
  getHistory: async ({ commit }, payload) => {
    try {
      commit(GET_API);
      const result = await getHistoryService(payload);
      commit(GET_HISTORY_SUCCESS, result);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  }
};
