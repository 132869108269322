<template>
  <nav class="topbar-container">
    <v-btn v-if="this.$route.name !== 'home' && this.$route.name !== 'manage_coupon'" @click="handleBack" icon color="#333333">
      <v-icon large>mdi-chevron-left</v-icon>
    </v-btn>
    <v-col class="topbar-inner">{{ title }} <div class="topbar-sub"> {{title_hospital_by}} {{this.$route.meta.title_select_by}}</div></v-col>
  </nav>
</template>

<script>
export default {
  data: () => ({
    localTitle: "",
    title_hospital_by: ""
  }),
  mounted() {
    this.$eventBus.$on("set-title-topbar", val => {
      this.localTitle = val;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("set-title-topbar", val => {
      this.localTitle = val;
    });
  },
  created() {
    this.title_hospital_by = this.$route.meta.title_hospital_by
  },
  computed: {
    title() {
      return this.localTitle || this.$route.meta.title || "";
    },
   
  },
  methods: {
    handleBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.topbar-container {
  display: flex;
  height: 70px;
  padding: 0 24px;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.25);
}
.topbar-inner {
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #333333;
}
.topbar-sub{
  color: gray;
  font-size: 70%;
}
</style>
