<template>
  <div>
    <!-- Basic Layout -->
    <header class="main-header"></header>
    <main class="main-content" :style="{ background: background }">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  computed: {
    background() {
      return this.$route.meta.background || "#ffffff";
    }
  }
};
</script>

<style scoped>
.main-header {
  position: relative;
  max-height: 70px;
  width: 100%;
}
.main-content {
  height: calc(100vh);
  overflow: auto;
  padding: 12px;
}
</style>
