<template>
  <div>
    <CardRedeem value="128" button="แลกคูปอง" goto="manage_detail" />

    <v-row align="center" justify="center" v-for="(item, index) in coupons" :key="index">
      <v-col cols="12">
        <v-card class="card_coupon">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-col cols="12">
                <v-img
                  :src="
                    item.couponDetail.couponImagePath ? item.couponDetail.couponImagePath : image
                  "
                ></v-img>
              </v-col>
              <v-row justify="center" no-gutters class="mt-4">
                <v-col cols="12" class="line"></v-col
              ></v-row>
              <v-col cols="12" class="mt-2 ml-2">
                <span>{{ item.couponName }}</span>
              </v-col>

              <v-col cols="12" class="mt-1">
                <v-row>
                  <v-col
                    @click="donateAmountDetail(item)"
                    v-ripple
                    cols="6"
                    class="line_vertical"
                    align="center"
                    style="margin-top: -15px"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <span class="font_title">ยอดบริจาค</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <span class="font_number">{{ item.couponDetail.donationAmount }} </span>
                        <span class="font_detail">ใบ</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    @click="couponAmountDetail(item)"
                    v-ripple
                    cols="6"
                    align="center"
                    style="margin-top: -15px"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <span class="font_title">ยอดคงเหลือ</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <span class="font_number">{{ item.couponDetail.collectRemaining }} </span>
                        <span class="font_detail">ใบ</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" align="center" style="margin-top: -10px">
                <v-btn
                  color="primary"
                  outlined
                  width="95%"
                  height="40px"
                  @click="goTo(item)"
                  :disabled="parseInt(item.couponDetail.collectRemaining) <= 0"
                >
                  <span class="font_select">แจก</span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="coupons.length <= 0">
      <v-col cols="12">
        ไม่มีคูปองบริจาค
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardRedeem from "../../components/card/card-redeem.vue";
// import TableAmount from "../../components/card/card-redeem.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { CardRedeem },
  data() {
    return {
      image: require("assets/image/coupon.png"),
      card_for: 5,
      agencyId: null
    };
  },
  watch: {},
  computed: {
    ...mapState("project", ["projects"]),
    ...mapState("coupon", ["coupons", "quataPoint", "couponAmount", "donateAmount"])
  },
  async mounted() {
    await this.getProjects();
    if (this.projects.length > 0) {
      this.getCoupons(this.projects[0].id);
      this.getQuotapoint(this.projects[0].id);
    }
  },
  methods: {
    ...mapActions("project", ["getProjects"]),
    ...mapActions("coupon", [
      "getCoupons",
      "selectCoupon",
      "getQuotapoint",
      "getCouponAmount",
      "getDonateAmount"
    ]),
    goTo(val) {
      this.selectCoupon(val);
      this.$router.push("/manage_detail");
    },
    async donateAmountDetail(v) {
      //await this.getDonateAmount(v);
      // console.log("ยอดบริจาค");
      // console.log(v.couponId);
      // console.log(v.agencyProjectId);
      // console.log(this.donateAmount, "++++");
      this.$router.push(`/manage_detail/donate_amount/${v.couponId}/${v.agencyProjectId}`);
    },
    async couponAmountDetail(v) {
      console.log(v);
      //await this.getCouponAmount(v);
      // console.log("ยอดคงเหลือ");
      // console.log(v.couponId);
      // console.log(v.agencyProjectId);
      // console.log("logggg", this.couponAmount);
      this.$router.push(`/manage_detail/coupon_amount/${v.couponId}/${v.agencyProjectId}`);
    }
  }
};
</script>

<style scoped>
.card_coupon {
  border-radius: 4px !important;
  /* color: #fff; */
  background-color: #fff;
}
.tag {
  /* width: 154px;
  height: 48px;
  
  background-color: #D32F2F;
  border-radius: 12px 6px 6px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; */
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 80px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 80px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.font_select {
  color: #1e4d94;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.font_number {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #000;
}
.btn_select {
  width: 90%;
  height: 40px;
  color: #1e4d94;
}
.font_detail {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
}
.line {
  position: absolute;
  color: #6594e0;
  border-bottom: 2px dashed #e0e0e0;
  width: 96%;
}
.line_vertical {
  /* border-left: 2px solid #eeeeee; */
  border-right: 2px solid #eeeeee;
  text-align: center;
}
.font_quota {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #1e4d94;
}
.font_btn_change {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #1e4d94;
}
</style>
