<template>
  <i-card>
    <div class="card-item">
      <div class="card-image">
        <img :src="localImage" />
      </div>
      <div class="card-content">
        <div class="card-title">{{ title }}</div>
        <div class="card-text-desc">{{ localDesc }}</div>
        <slot name="action" />
      </div>
    </div>
  </i-card>
</template>

<script>
export default {
  props: ["image", "title", "desc"],
  computed: {
    localImage() {
      console.log(this.image);
      const baseUrl = process.env.BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.image ? `${baseUrl}/spring/api/media/image/${this.image}` : image;
    },
    localDesc() {
      return this.title && this.title.length > 40
        ? `${this.title.substring(0, 40)}...`
        : this.title;
    }
  }
};
</script>

<style scoped>
.card-item {
  display: flex;
}
.card-image {
  flex: 1;
  overflow: hidden;
  border-radius: 10px;
  max-width: 100px;
  height: 100px;
  min-width: 100px;
}
.card-content {
  flex: 2;
  min-width: 100px;
  padding-left: 10px;
}
.card-content .card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  height: 22px;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-content .card-text-desc {
  width: 100%;
  flex: 1;
  word-wrap: break-word;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #aeaeae;
  padding: 3px 0;
  height: 50px;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}
</style>
